/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      title
      subtitle
      description
      image
      location {
        lon
        lat
      }
      onHubs
      sceneUrl
      rooms {
        items {
          id
          projectID
          project {
            id
            title
            subtitle
            description
            image
            onHubs
            sceneUrl
            website
            email
            ownerId
            ownerName
            ownerBio
            approved
            mobileFriendly
            featured
            mohuniRoomboardId
            createdAt
            updatedAt
          }
          hubsId
          userCountLobby
          userCountRoom
          createdAt
          updatedAt
        }
        nextToken
      }
      website
      email
      ownerId
      ownerName
      ownerBio
      approved
      mobileFriendly
      featured
      tags {
        items {
          id
          projectID
          tagID
          project {
            id
            title
            subtitle
            description
            image
            onHubs
            sceneUrl
            website
            email
            ownerId
            ownerName
            ownerBio
            approved
            mobileFriendly
            featured
            mohuniRoomboardId
            createdAt
            updatedAt
          }
          tag {
            id
            name
            mainTagID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      mohuniRoomboardId
      createdAt
      updatedAt
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      title
      subtitle
      description
      image
      location {
        lon
        lat
      }
      onHubs
      sceneUrl
      rooms {
        items {
          id
          projectID
          project {
            id
            title
            subtitle
            description
            image
            onHubs
            sceneUrl
            website
            email
            ownerId
            ownerName
            ownerBio
            approved
            mobileFriendly
            featured
            mohuniRoomboardId
            createdAt
            updatedAt
          }
          hubsId
          userCountLobby
          userCountRoom
          createdAt
          updatedAt
        }
        nextToken
      }
      website
      email
      ownerId
      ownerName
      ownerBio
      approved
      mobileFriendly
      featured
      tags {
        items {
          id
          projectID
          tagID
          project {
            id
            title
            subtitle
            description
            image
            onHubs
            sceneUrl
            website
            email
            ownerId
            ownerName
            ownerBio
            approved
            mobileFriendly
            featured
            mohuniRoomboardId
            createdAt
            updatedAt
          }
          tag {
            id
            name
            mainTagID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      mohuniRoomboardId
      createdAt
      updatedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      title
      subtitle
      description
      image
      location {
        lon
        lat
      }
      onHubs
      sceneUrl
      rooms {
        items {
          id
          projectID
          project {
            id
            title
            subtitle
            description
            image
            onHubs
            sceneUrl
            website
            email
            ownerId
            ownerName
            ownerBio
            approved
            mobileFriendly
            featured
            mohuniRoomboardId
            createdAt
            updatedAt
          }
          hubsId
          userCountLobby
          userCountRoom
          createdAt
          updatedAt
        }
        nextToken
      }
      website
      email
      ownerId
      ownerName
      ownerBio
      approved
      mobileFriendly
      featured
      tags {
        items {
          id
          projectID
          tagID
          project {
            id
            title
            subtitle
            description
            image
            onHubs
            sceneUrl
            website
            email
            ownerId
            ownerName
            ownerBio
            approved
            mobileFriendly
            featured
            mohuniRoomboardId
            createdAt
            updatedAt
          }
          tag {
            id
            name
            mainTagID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      mohuniRoomboardId
      createdAt
      updatedAt
    }
  }
`;
export const createRoom = /* GraphQL */ `
  mutation CreateRoom(
    $input: CreateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    createRoom(input: $input, condition: $condition) {
      id
      projectID
      project {
        id
        title
        subtitle
        description
        image
        location {
          lon
          lat
        }
        onHubs
        sceneUrl
        rooms {
          items {
            id
            projectID
            hubsId
            userCountLobby
            userCountRoom
            createdAt
            updatedAt
          }
          nextToken
        }
        website
        email
        ownerId
        ownerName
        ownerBio
        approved
        mobileFriendly
        featured
        tags {
          items {
            id
            projectID
            tagID
            createdAt
            updatedAt
          }
          nextToken
        }
        mohuniRoomboardId
        createdAt
        updatedAt
      }
      hubsId
      userCountLobby
      userCountRoom
      createdAt
      updatedAt
    }
  }
`;
export const updateRoom = /* GraphQL */ `
  mutation UpdateRoom(
    $input: UpdateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    updateRoom(input: $input, condition: $condition) {
      id
      projectID
      project {
        id
        title
        subtitle
        description
        image
        location {
          lon
          lat
        }
        onHubs
        sceneUrl
        rooms {
          items {
            id
            projectID
            hubsId
            userCountLobby
            userCountRoom
            createdAt
            updatedAt
          }
          nextToken
        }
        website
        email
        ownerId
        ownerName
        ownerBio
        approved
        mobileFriendly
        featured
        tags {
          items {
            id
            projectID
            tagID
            createdAt
            updatedAt
          }
          nextToken
        }
        mohuniRoomboardId
        createdAt
        updatedAt
      }
      hubsId
      userCountLobby
      userCountRoom
      createdAt
      updatedAt
    }
  }
`;
export const deleteRoom = /* GraphQL */ `
  mutation DeleteRoom(
    $input: DeleteRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    deleteRoom(input: $input, condition: $condition) {
      id
      projectID
      project {
        id
        title
        subtitle
        description
        image
        location {
          lon
          lat
        }
        onHubs
        sceneUrl
        rooms {
          items {
            id
            projectID
            hubsId
            userCountLobby
            userCountRoom
            createdAt
            updatedAt
          }
          nextToken
        }
        website
        email
        ownerId
        ownerName
        ownerBio
        approved
        mobileFriendly
        featured
        tags {
          items {
            id
            projectID
            tagID
            createdAt
            updatedAt
          }
          nextToken
        }
        mohuniRoomboardId
        createdAt
        updatedAt
      }
      hubsId
      userCountLobby
      userCountRoom
      createdAt
      updatedAt
    }
  }
`;
export const createProjectTag = /* GraphQL */ `
  mutation CreateProjectTag(
    $input: CreateProjectTagInput!
    $condition: ModelProjectTagConditionInput
  ) {
    createProjectTag(input: $input, condition: $condition) {
      id
      projectID
      tagID
      project {
        id
        title
        subtitle
        description
        image
        location {
          lon
          lat
        }
        onHubs
        sceneUrl
        rooms {
          items {
            id
            projectID
            hubsId
            userCountLobby
            userCountRoom
            createdAt
            updatedAt
          }
          nextToken
        }
        website
        email
        ownerId
        ownerName
        ownerBio
        approved
        mobileFriendly
        featured
        tags {
          items {
            id
            projectID
            tagID
            createdAt
            updatedAt
          }
          nextToken
        }
        mohuniRoomboardId
        createdAt
        updatedAt
      }
      tag {
        id
        name
        projects {
          items {
            id
            projectID
            tagID
            createdAt
            updatedAt
          }
          nextToken
        }
        mainTagID
        mainTag {
          id
          name
          tags {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProjectTag = /* GraphQL */ `
  mutation UpdateProjectTag(
    $input: UpdateProjectTagInput!
    $condition: ModelProjectTagConditionInput
  ) {
    updateProjectTag(input: $input, condition: $condition) {
      id
      projectID
      tagID
      project {
        id
        title
        subtitle
        description
        image
        location {
          lon
          lat
        }
        onHubs
        sceneUrl
        rooms {
          items {
            id
            projectID
            hubsId
            userCountLobby
            userCountRoom
            createdAt
            updatedAt
          }
          nextToken
        }
        website
        email
        ownerId
        ownerName
        ownerBio
        approved
        mobileFriendly
        featured
        tags {
          items {
            id
            projectID
            tagID
            createdAt
            updatedAt
          }
          nextToken
        }
        mohuniRoomboardId
        createdAt
        updatedAt
      }
      tag {
        id
        name
        projects {
          items {
            id
            projectID
            tagID
            createdAt
            updatedAt
          }
          nextToken
        }
        mainTagID
        mainTag {
          id
          name
          tags {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProjectTag = /* GraphQL */ `
  mutation DeleteProjectTag(
    $input: DeleteProjectTagInput!
    $condition: ModelProjectTagConditionInput
  ) {
    deleteProjectTag(input: $input, condition: $condition) {
      id
      projectID
      tagID
      project {
        id
        title
        subtitle
        description
        image
        location {
          lon
          lat
        }
        onHubs
        sceneUrl
        rooms {
          items {
            id
            projectID
            hubsId
            userCountLobby
            userCountRoom
            createdAt
            updatedAt
          }
          nextToken
        }
        website
        email
        ownerId
        ownerName
        ownerBio
        approved
        mobileFriendly
        featured
        tags {
          items {
            id
            projectID
            tagID
            createdAt
            updatedAt
          }
          nextToken
        }
        mohuniRoomboardId
        createdAt
        updatedAt
      }
      tag {
        id
        name
        projects {
          items {
            id
            projectID
            tagID
            createdAt
            updatedAt
          }
          nextToken
        }
        mainTagID
        mainTag {
          id
          name
          tags {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      name
      projects {
        items {
          id
          projectID
          tagID
          project {
            id
            title
            subtitle
            description
            image
            onHubs
            sceneUrl
            website
            email
            ownerId
            ownerName
            ownerBio
            approved
            mobileFriendly
            featured
            mohuniRoomboardId
            createdAt
            updatedAt
          }
          tag {
            id
            name
            mainTagID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      mainTagID
      mainTag {
        id
        name
        tags {
          items {
            id
            name
            mainTagID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      name
      projects {
        items {
          id
          projectID
          tagID
          project {
            id
            title
            subtitle
            description
            image
            onHubs
            sceneUrl
            website
            email
            ownerId
            ownerName
            ownerBio
            approved
            mobileFriendly
            featured
            mohuniRoomboardId
            createdAt
            updatedAt
          }
          tag {
            id
            name
            mainTagID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      mainTagID
      mainTag {
        id
        name
        tags {
          items {
            id
            name
            mainTagID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      name
      projects {
        items {
          id
          projectID
          tagID
          project {
            id
            title
            subtitle
            description
            image
            onHubs
            sceneUrl
            website
            email
            ownerId
            ownerName
            ownerBio
            approved
            mobileFriendly
            featured
            mohuniRoomboardId
            createdAt
            updatedAt
          }
          tag {
            id
            name
            mainTagID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      mainTagID
      mainTag {
        id
        name
        tags {
          items {
            id
            name
            mainTagID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMainTag = /* GraphQL */ `
  mutation CreateMainTag(
    $input: CreateMainTagInput!
    $condition: ModelMainTagConditionInput
  ) {
    createMainTag(input: $input, condition: $condition) {
      id
      name
      tags {
        items {
          id
          name
          projects {
            nextToken
          }
          mainTagID
          mainTag {
            id
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMainTag = /* GraphQL */ `
  mutation UpdateMainTag(
    $input: UpdateMainTagInput!
    $condition: ModelMainTagConditionInput
  ) {
    updateMainTag(input: $input, condition: $condition) {
      id
      name
      tags {
        items {
          id
          name
          projects {
            nextToken
          }
          mainTagID
          mainTag {
            id
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMainTag = /* GraphQL */ `
  mutation DeleteMainTag(
    $input: DeleteMainTagInput!
    $condition: ModelMainTagConditionInput
  ) {
    deleteMainTag(input: $input, condition: $condition) {
      id
      name
      tags {
        items {
          id
          name
          projects {
            nextToken
          }
          mainTagID
          mainTag {
            id
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
