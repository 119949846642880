import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { useLeaflet, withLeaflet, Marker, Popup } from "react-leaflet"
import ContextConsumer from "../components/context/Context"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import Logo from "../images/Logo_Ars Electronica Festival_black.png"

import Amplify, { API, graphqlOperation } from "aws-amplify"
import { createProject } from "../graphql/mutations"
import { listProjects } from "../graphql/queries"
import { onUpdateProject } from "../graphql/subscriptions"

import awsExports from "../aws-exports"

Amplify.configure(awsExports)

const initialState = {
  title: "",
  subtitle: "",
  description: "",
  onHubs: false,
  sceneUrl: "",
  email: "",
  ownerBio: "",
  website: "",
  rooms: [],
}

const Page2 = props => {
  const mapState = {
    lat: 51.105,
    lng: -0.09,
    zoom: 13,
  }
  const mapState2 = {
    lat: 51.1015,
    lng: -0.019,
    zoom: 13,
  }

  const position = [mapState.lat, mapState.lng]
  const position2 = [mapState2.lat, mapState2.lng]

  useEffect(() => {
    props.addMarker([
      { position: position, text: "Page 2!" },
      { position: position2, text: "Numero Duo" },
    ])
    props.flyTo(position)
  }, [])

  return (
    <section>
      <SEO title="Home" />
      <div>
        <h1>Mozilla Hubs Navigation Interface</h1>
      </div>
      <h2>
        {/* <Link to="/beammeup">Beam me up!</Link> */}
        <Link to="/">Home</Link>
      </h2>
      <Link to="/partner/submit">Submit a project</Link> <br />
    </section>
  )
}

export default Page2
